import React from 'react'
import {useNavigate} from "react-router-dom";
import {NavLink} from "react-router-dom";
import { openMailer } from '../scripts/common.js';
import {navClickedGAEvent, linkClickedGAEvent} from "../scripts/ga4.js"
import { useState, useEffect } from "react";

const TopMenuComponent = (props) => {
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  let mobileMenuClass = mobileMenuVisible ? mobileMenuOpen ? 'mobile-menu-open' : 'mobile-menu-closed' : 'mobile-menu-invisible';
  return (
    <>
    <div id="top_menu_container">
      <div id="top_menu_fixed">
        <div id="top_menu_name">
            <span aria-label="Go To Home" onClick={(e) => {navClickedGAEvent('name-side'); navigate("/home");}}>Aleksandra Kukielko</span>
        </div>
         <div id="top_menu_nav">
              <NavLink onClick={()=>{navClickedGAEvent('about')}} to={"/about"} aria-label="Go To About Me" >
                About
              </NavLink>
              <NavLink onClick={()=>{navClickedGAEvent('experience')}} to={"/experience"}aria-label="Go To My Projects">
                Projects
              </NavLink>
              <NavLink onClick={()=>{navClickedGAEvent('contact')}} to={"/contact"} aria-label="Go To Contact Page">
                Contact
              </NavLink>
              <NavLink onClick={()=>{navClickedGAEvent('journal')}} to={"/journal"} aria-label="Go To My Journal">
                Journal
              </NavLink>
        </div> 
        <div id="top_menu_spacer">
          <svg onClick={()=>{setMobileMenuVisible(true);setMobileMenuOpen(!mobileMenuOpen)}} viewBox="0 0 24 24" width="100%" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 18L20 18" stroke="var(--neutral)" strokeWidth="2" strokeLinecap="round"/>
            <path d="M4 12L20 12" stroke="var(--neutral)" strokeWidth="2" strokeLinecap="round"/>
            <path d="M4 6L20 6" stroke="var(--neutral)" strokeWidth="2" strokeLinecap="round"/>
          </svg>
        </div>
       <div id="top_menu_links">
                <a onClick={()=>{linkClickedGAEvent('github')}} aria-label="Go To My GitHub" href="https://github.com/olakukielko/" target="_blank" rel="noopener noreferrer">
                    <svg fill="var(--neutral)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path fillRule="evenodd" clipRule="evenodd" d="M48.854 0C21.839 0 0 22 0 49.217c0 21.756 13.993 40.172 33.405 46.69 2.427.49 3.316-1.059 3.316-2.362 0-1.141-.08-5.052-.08-9.127-13.59 2.934-16.42-5.867-16.42-5.867-2.184-5.704-5.42-7.17-5.42-7.17-4.448-3.015.324-3.015.324-3.015 4.934.326 7.523 5.052 7.523 5.052 4.367 7.496 11.404 5.378 14.235 4.074.404-3.178 1.699-5.378 3.074-6.6-10.839-1.141-22.243-5.378-22.243-24.283 0-5.378 1.94-9.778 5.014-13.2-.485-1.222-2.184-6.275.486-13.038 0 0 4.125-1.304 13.426 5.052a46.97 46.97 0 0 1 12.214-1.63c4.125 0 8.33.571 12.213 1.63 9.302-6.356 13.427-5.052 13.427-5.052 2.67 6.763.97 11.816.485 13.038 3.155 3.422 5.015 7.822 5.015 13.2 0 18.905-11.404 23.06-22.324 24.283 1.78 1.548 3.316 4.481 3.316 9.126 0 6.6-.08 11.897-.08 13.526 0 1.304.89 2.853 3.316 2.364 19.412-6.52 33.405-24.935 33.405-46.691C97.707 22 75.788 0 48.854 0z"/></svg>
                  </a>
                  <a onClick={()=>{linkClickedGAEvent('linkedin')}} aria-label="Go To My LinkedIn" href="https://www.linkedin.com/in/akukielk/" target="_blank" rel="noopener noreferrer">
                    <svg fill="var(--neutral)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 16h-2v-6h2v6zm-1-6.891c-.607 0-1.1-.496-1.1-1.109 0-.612.492-1.109 1.1-1.109s1.1.497 1.1 1.109c0 .613-.493 1.109-1.1 1.109zm8 6.891h-1.998v-2.861c0-1.881-2.002-1.722-2.002 0v2.861h-2v-6h2v1.093c.872-1.616 4-1.736 4 1.548v3.359z"/></svg>
                  </a>
                  <button aria-label="Send Me An Email" onClick={() => {linkClickedGAEvent('mail'); openMailer()}}>
                    <svg fill="var(--neutral)" strokeWidth="0" viewBox="0 0 300 300" shapeRendering="geometricPrecision" textRendering="geometricPrecision"><path d="M0.0001,150.00008c0-82.84266,67.15725-149.99991,149.99991-149.99991s149.99991,67.15725,149.99991,149.99991-67.15725,149.99991-149.99991,149.99991-149.99991-67.15725-149.99991-149.99991ZM150.00001,276.81132c70.03592,0,126.81124-56.77533,126.81124-126.81124s-56.77533-126.81124-126.81124-126.81124-126.81124,56.77533-126.81124,126.81124s56.77533,126.81124,126.81124,126.81124ZM23.18889,150.00007c0-70.03592,56.77528-126.81126,126.81112-126.81126s126.81112,56.77534,126.81112,126.81126-56.77528,126.81126-126.81112,126.81126-126.81112-56.77534-126.81112-126.81126Zm126.81112,117.2942c64.77973,0,117.29407-52.5144,117.29407-117.2942s-52.51434-117.2942-117.29407-117.2942-117.29407,52.5144-117.29407,117.2942s52.51434,117.2942,117.29407,117.2942ZM32.706,150.00007c0-64.77969,52.51432-117.29401,117.29401-117.29401s117.29401,52.51432,117.29401,117.29401-52.51432,117.29401-117.29401,117.29401-117.29401-52.51432-117.29401-117.29401Zm209.08284,59.52647v-119.05293h-183.57765v119.05293h183.57765ZM64.80251,202.82629v-105.65242l85.1975,52.8262l85.1975-52.8262v105.65242h-170.395Z"></path></svg>                 
                  </button>
        </div> 
      </div>
      <div id="top_menu_filler" className={mobileMenuClass}>
          <div id="top_menu_nav_mobile">
              <NavLink onClick={()=>{navClickedGAEvent('about')}} to={"/about"} aria-label="Go To About Me" >
                  About
                </NavLink>
                <NavLink onClick={()=>{navClickedGAEvent('experience')}} to={"/experience"}aria-label="Go To My Projects">
                  Projects
                </NavLink>
                <NavLink onClick={()=>{navClickedGAEvent('contact')}} to={"/contact"} aria-label="Go To Contact Page">
                  Contact
                </NavLink>
                <NavLink onClick={()=>{navClickedGAEvent('journal')}} to={"/journal"} aria-label="Go To My Journal">
                  Journal
                </NavLink>
          </div>
          <div id="top_menu_links_mobile">
                  <a onClick={()=>{linkClickedGAEvent('github')}} aria-label="Go To My GitHub" href="https://github.com/olakukielko/" target="_blank" rel="noopener noreferrer">
                      <svg fill="var(--neutral)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path fillRule="evenodd" clipRule="evenodd" d="M48.854 0C21.839 0 0 22 0 49.217c0 21.756 13.993 40.172 33.405 46.69 2.427.49 3.316-1.059 3.316-2.362 0-1.141-.08-5.052-.08-9.127-13.59 2.934-16.42-5.867-16.42-5.867-2.184-5.704-5.42-7.17-5.42-7.17-4.448-3.015.324-3.015.324-3.015 4.934.326 7.523 5.052 7.523 5.052 4.367 7.496 11.404 5.378 14.235 4.074.404-3.178 1.699-5.378 3.074-6.6-10.839-1.141-22.243-5.378-22.243-24.283 0-5.378 1.94-9.778 5.014-13.2-.485-1.222-2.184-6.275.486-13.038 0 0 4.125-1.304 13.426 5.052a46.97 46.97 0 0 1 12.214-1.63c4.125 0 8.33.571 12.213 1.63 9.302-6.356 13.427-5.052 13.427-5.052 2.67 6.763.97 11.816.485 13.038 3.155 3.422 5.015 7.822 5.015 13.2 0 18.905-11.404 23.06-22.324 24.283 1.78 1.548 3.316 4.481 3.316 9.126 0 6.6-.08 11.897-.08 13.526 0 1.304.89 2.853 3.316 2.364 19.412-6.52 33.405-24.935 33.405-46.691C97.707 22 75.788 0 48.854 0z"/></svg>
                    </a>
                    <a onClick={()=>{linkClickedGAEvent('linkedin')}} aria-label="Go To My LinkedIn" href="https://www.linkedin.com/in/akukielk/" target="_blank" rel="noopener noreferrer">
                      <svg fill="var(--neutral)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 16h-2v-6h2v6zm-1-6.891c-.607 0-1.1-.496-1.1-1.109 0-.612.492-1.109 1.1-1.109s1.1.497 1.1 1.109c0 .613-.493 1.109-1.1 1.109zm8 6.891h-1.998v-2.861c0-1.881-2.002-1.722-2.002 0v2.861h-2v-6h2v1.093c.872-1.616 4-1.736 4 1.548v3.359z"/></svg>
                    </a>
                    <button aria-label="Send Me An Email" onClick={() => {linkClickedGAEvent('mail'); openMailer()}}>
                      <svg fill="var(--neutral)" strokeWidth="0" viewBox="0 0 300 300" shapeRendering="geometricPrecision" textRendering="geometricPrecision"><path d="M0.0001,150.00008c0-82.84266,67.15725-149.99991,149.99991-149.99991s149.99991,67.15725,149.99991,149.99991-67.15725,149.99991-149.99991,149.99991-149.99991-67.15725-149.99991-149.99991ZM150.00001,276.81132c70.03592,0,126.81124-56.77533,126.81124-126.81124s-56.77533-126.81124-126.81124-126.81124-126.81124,56.77533-126.81124,126.81124s56.77533,126.81124,126.81124,126.81124ZM23.18889,150.00007c0-70.03592,56.77528-126.81126,126.81112-126.81126s126.81112,56.77534,126.81112,126.81126-56.77528,126.81126-126.81112,126.81126-126.81112-56.77534-126.81112-126.81126Zm126.81112,117.2942c64.77973,0,117.29407-52.5144,117.29407-117.2942s-52.51434-117.2942-117.29407-117.2942-117.29407,52.5144-117.29407,117.2942s52.51434,117.2942,117.29407,117.2942ZM32.706,150.00007c0-64.77969,52.51432-117.29401,117.29401-117.29401s117.29401,52.51432,117.29401,117.29401-52.51432,117.29401-117.29401,117.29401-117.29401-52.51432-117.29401-117.29401Zm209.08284,59.52647v-119.05293h-183.57765v119.05293h183.57765ZM64.80251,202.82629v-105.65242l85.1975,52.8262l85.1975-52.8262v105.65242h-170.395Z"></path></svg>                 
                    </button>
          </div>
        </div>
    </div>
    </>
  );
};

export default TopMenuComponent;
