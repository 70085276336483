import React, {useEffect} from 'react'
import {sendGAPageView} from "../scripts/ga4.js"

export default function SiteUnderConstruction() {
  useEffect(() => {
    sendGAPageView("/site-locked", "SiteLocked")
  }, []);
  return (
    <>
            <div id="site-locked" data-testid="site-locked">
                <h2>The site is under construction - exciting changes are coming soon!</h2>
            </div>
    </>
  )
}