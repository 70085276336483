import React, {useEffect} from 'react'
import ExperienceComponent from '../components/ExperienceComponent.js';
import TopMenuComponent from '../components/TopMenuComponent.js';
import {sendGAPageView} from "../scripts/ga4.js"

export default function Experience() {
  useEffect(() => {
    sendGAPageView("/experience", "Experience")
  }, []);
  return (
    <>
    <TopMenuComponent></TopMenuComponent>
    <div className="main_content">
      <ExperienceComponent></ExperienceComponent>
    </div>
    </>
  )
}
