import React, { useState, useEffect } from "react";
import {
  Link,
} from "react-router-dom";
import { setThemeColors } from "../scripts/common";
import { get_data } from "../scripts/webservice";
const JournalComponent = () => {
  const [JournalData, setJournalData] = useState(null);
  
  useEffect(() => {
    fetchData();
    setThemeColors("var(--palette-dark)", "var(--neutral)");
  }, []);

  const fetchData = async () => {
    let data = await get_data("Journal")
    setJournalData(data)
  }
  return (
    <>
      <div id="journal_container" data-testid="journal_container" className="flexCol">
        <div id="journal_box" className="flexCol">
            <div className="divTitle">
                <h2><strong>JOURNAL</strong></h2>
                <h3>My odd thoughts, here and there.</h3>
            </div> 
            {JournalData == null ?<></>: <div id="journal_main">
              {JournalData.length > 0 ?
                  <>
                {JournalData.map(item => 
                  <div className="journal_item">
                    <div className="journal_main">
                    <div className="journal_top">
                      <h2 className="journal_title"><a>{item.entry_title}</a></h2>
                    </div>
                    <h5 className="journal_date">{item.entry_date}</h5>
                    <hr></hr>
                    <div className="journal_body_preview">{item.entry_preview}</div>
                    </div>
                    <Link className="journal_nav" to={`/journalentry/${item.id}`}><span className="journal_readMore">KEEP READING</span></Link>
                </div>
                )}
                </>
              :
              <div className="journal_item">
                <div className="journal_main">
                <div className="journal_top">
                  <h2 className="journal_title"><a>No results found!</a></h2>
                </div>
                <h5 className="journal_date"></h5>
                <hr></hr>
                <div className="journal_body_preview">Check back soon...</div>
                </div>
              </div>
              }

                
            </div> }
           
        </div>
        <div id="journal_divider_2">
            <svg viewBox="0 0 1440 320">
              <path className='divider_wave' d="M0,224L26.7,213.3C53.3,203,107,181,160,181.3C213.3,181,267,203,320,192C373.3,181,427,139,480,138.7C533.3,139,587,181,640,192C693.3,203,747,181,800,181.3C853.3,181,907,203,960,213.3C1013.3,224,1067,224,1120,208C1173.3,192,1227,160,1280,170.7C1333.3,181,1387,235,1413,261.3L1440,288L1440,320L1413.3,320C1386.7,320,1333,320,1280,320C1226.7,320,1173,320,1120,320C1066.7,320,1013,320,960,320C906.7,320,853,320,800,320C746.7,320,693,320,640,320C586.7,320,533,320,480,320C426.7,320,373,320,320,320C266.7,320,213,320,160,320C106.7,320,53,320,27,320L0,320Z"></path>
            </svg>
        </div>
     </div>
    </>
  );
};

export default JournalComponent;
