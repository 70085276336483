export var setThemeColors = function(topMenuBgColor, topMenuTextColor) {
    if (document.getElementById("top_menu_fixed")){
        document.getElementById("top_menu_fixed").style.background = topMenuBgColor;
    }
    if (document.getElementById("top_menu_filler")){
        document.getElementById("top_menu_filler").style.background = topMenuBgColor;
    }
    if (document.getElementById("top_menu_name")){
        document.getElementById("top_menu_name").style.color = topMenuTextColor;
    }
    Array.prototype.forEach.call(document.querySelectorAll("#top_menu_nav a"), (i) => {
        i.style.color = topMenuTextColor;
    })
    Array.prototype.forEach.call(document.querySelectorAll("#top_menu_links svg"), (i) => {
        i.style.fill = topMenuTextColor;
    })
    Array.prototype.forEach.call(document.querySelectorAll("#top_menu_spacer svg path"), (i) => {
        i.style.stroke = topMenuTextColor;
    })
    Array.prototype.forEach.call(document.querySelectorAll("#top_menu_nav_mobile a"), (i) => {
        i.style.color = topMenuTextColor;
    })
    Array.prototype.forEach.call(document.querySelectorAll("#top_menu_links_mobile svg"), (i) => {
        i.style.fill = topMenuTextColor;
    })
}
//decode rot13 encoded string
var decodeRot13 = function(a) {
    return a.replace(/[a-zA-Z]/g, function(c){
      return String.fromCharCode((c <= "Z" ? 90 : 122) >= (c = c.charCodeAt(0) + 13) 
                                 ? c : c - 26);
    })
}; 
//decode email and open mail app
export var openMailer = function(element) {
    var decoded = decodeRot13("nyrxfqbrfpbqr@tznvy.pbz");
    window.location.href = "mailto:"+decoded;
};