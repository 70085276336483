import React, {useEffect} from 'react'
import WelcomeComponent from '../components/WelcomeComponent.js';
import {sendGAPageView} from "../scripts/ga4.js"

export default function Home() {
  useEffect(() => {
    sendGAPageView("/home", "Home")
  },[]);
  return (
    <>
    <WelcomeComponent></WelcomeComponent>
    </>
  );
}
