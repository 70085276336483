const BASE_URL = function() { return process.env.REACT_APP_API_URL };
const contactFormResponse = {
  success: { response: "ok", response_title: "Thank you!", response_body: "Your message was received.", smtp_success: 1},
  fail: { response: "fail", response_title: "OH NO :(", response_body: "ReCaptcha Error", smtp_success: 0}
}
export async function get_data(pagename, id){
    if (pagename.toString().toLowerCase().trim() == "experience"){
          //get projects
          let dataProj = await get_project_data();       
          //get tags
          let dataTags = await get_tag_data();
          return [dataProj.data, dataTags.data]
       }
      else if (pagename.toString().toLowerCase().trim() == "journal"){
        //get journal entries
        let journalEntries = await get_journal_data()       
        return journalEntries.data;
      }
      else if (pagename.toString().toLowerCase().trim() == "journalentry"){
        //get journal entries
        let entry = await get_journal_data_by_entry(id);
        return entry.data;
      }
}
export async function get_project_data(retries = 1) {
  try {
    const res = {statusCode:null, data: []};
    const response = await fetch(BASE_URL() + "/api/projects", {
      method: "GET",
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ window.localStorage.getItem('access_token')
      }
    }); 
    res.statusCode = response.status;
    if (retries > 0 && response.status == 401){
      await get_token();
      return await get_project_data(0);
    }
    if (!response.ok) {
      console.error('Failed to fetch data');
      return res;
    }
    const data = await response.json();
    if (data){
      res.data = data;
    }
    return res;
  } catch (error) {
      console.error('Error fetching data:', error);
      return {statusCode:null, data: []}; 
  }
}
export async function get_tag_data(retries = 1) {
  try {
    const res = {statusCode:null, data: []};
    const response = await fetch(BASE_URL() + "/api/projects/tags", {
      method: "GET",
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ window.localStorage.getItem('access_token')
      }
    }); 
    res.statusCode = response.status;
    if (retries > 0 && response.status == 401){
      await get_token();
      return await get_tag_data(0);
    }
    if (!response.ok) {
      console.error('Failed to fetch data');
      return res;
    }
    const data = await response.json();
    if (data){
      res.data = data;
    }
    return res;
  } catch (error) {
      console.error('Error fetching data:', error);
      return {statusCode:null, data: []}; 
  }
}
export async function get_journal_data(retries = 1) {
  try {
    const res = {statusCode:null, data: []};
    const response = await fetch(BASE_URL() + "/api/journal/list", {
      method: "GET",
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ window.localStorage.getItem('access_token')
      }
    }); 
    res.statusCode = response.status;
    if (retries > 0 && response.status == 401){
      await get_token();
      return await get_journal_data(0);
    }
    if (!response.ok) {
      console.error('Failed to fetch data');
      return res;
    }
    const data = await response.json();
    if (data){
      res.data = data;
    }
    return res;
  } catch (error) {
      console.error('Error fetching data:', error);
      return {statusCode:null, data: []}; 
  }
}
export async function get_journal_data_by_entry(entry_id, retries = 1) {
  try {
    const res = {statusCode:null, data: {entry_title: "Not found.", entry_body: ["So sorry! This entry doesn't exist."]}};
    const response = await fetch(BASE_URL() + "/api/journal/" + entry_id.toString(), {
      method: "GET",
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ window.localStorage.getItem('access_token')
      }
    }); 
    res.statusCode = response.status;
    if (retries > 0 && response.status == 401){
      await get_token();
      return await get_journal_data_by_entry(entry_id, 0);
    }
    if (!response.ok) {
      console.error('Failed to fetch data');
      return res;
    }
    const data = await response.json();
    if (data){
      res.data = data[0];
      res.data.entry_body = res.data.entry_body.split("\",\"");
    }
    return res;
  } catch (error) {
      console.error('Error fetching data:', error);
      return {statusCode:null, data: {}}; 
  }
}
async function verify_captcha(captchaVal){
  try{
    const response = await fetch("/verify-captcha", {
      method: "POST",
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify({g_recaptcha_response : captchaVal})
    });
    return response.status == 200;
  }
  catch (error) {
    console.error('Error fetching data:', error);
    return false;
  }
}
export async function submit_contact_form(captchaVal, form_name, form_email, form_body, retries = 1){
  try {
    if (await verify_captcha(captchaVal)){
      const _body = {
        form_name: form_name,
        form_email: form_email, 
        form_body: form_body
      }
      const response = await fetch(BASE_URL() + "/api/contact/send", {
        method: "POST",
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ window.localStorage.getItem('access_token')
        },
        body: JSON.stringify(_body)
      }); 
      if (retries > 0 && response.status == 401){
        await get_token();
        return await submit_contact_form(captchaVal, form_name, form_email, form_body, 0);
      }
      if (!response.ok) {
        console.error('Failed to fetch data');
        return contactFormResponse.fail;
      }
      return contactFormResponse.success;
    }
  } catch (error) {
      console.error('Error fetching data:', error);
      return contactFormResponse.fail;
  }
}
async function get_token(){
  try {
    const response = await fetch("/get-token", {
      method: "GET",
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }}); 
    if (!response.ok) {
      console.error('Failed to fetch data');
    }
    const data = await response.json();
    if (data){
      window.localStorage.setItem('access_token', data.token);
    }
  } catch (error) {
      console.error('Error fetching data:', error);
  }
}