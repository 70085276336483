import React, {useEffect} from 'react'
import TopMenuComponent from '../components/TopMenuComponent.js'
import JournalEntryComponent from '../components/JournalEntryComponent.js'
import {sendGAPageView} from "../scripts/ga4.js"

export default function Journal() {
  useEffect(() => {
    sendGAPageView("/journal", "Journal")
  }, []);
  return (
    <>
    <TopMenuComponent></TopMenuComponent> 
    <div className="main_content">
      <JournalEntryComponent></JournalEntryComponent>
    </div>
    </>
  )
}