import React, {useEffect} from 'react'
import ContactFormComponent from '../components/ContactFormComponent';
import TopMenuComponent from '../components/TopMenuComponent.js';
import {sendGAPageView} from "../scripts/ga4.js"

export default function Contact() {
  useEffect(() => {
    sendGAPageView("/contact", "Contact")
},[]);
  return (
    <>
    <TopMenuComponent></TopMenuComponent>
    <div className="main_content">
         <ContactFormComponent useCaptcha={true}></ContactFormComponent>
      </div>
    </>
         
  )
}
