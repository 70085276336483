import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Experience from './pages/Experience';
import React, {useState, useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import "./style/vars.css"
import "./style/animation.css"
import "./style/common.css"
import './style/menu.css'
import "./style/journal.css"
import './style/home.css'
import './style/experience.css';
import "./style/about.css"
import './style/contact.css'
import './style/site-locked.css'

import Journal from './pages/Journal.js';
import JournalEntry from "./pages/JournalEntry.js"
import ReactGA from 'react-ga4';
import SiteLocked from './pages/SiteLocked.js';

function App({ nonce }) {
  useEffect(() => { 
    ReactGA.initialize("G-L02LR4QNFS",{ nonce : nonce});
  }, []);
  return (
      <div className="app">
        <Router>
          <Routes>
            <Route path="/home"element={<Home/>}/>
            <Route path="/about" element={<About/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/experience" element={<Experience/>} />
            <Route path="/journal" element={<Journal/>} />
            <Route path="/journalentry/:id" element={<JournalEntry/>} />
            <Route path="/site-locked" element={<SiteLocked/>} />
          </Routes>
        </Router>
      </div>
  );
}

export default App;
