import React, { useState, useEffect, useContext } from "react";
import {readMoreClickedGAEvent, linkClickedGAEvent} from "../scripts/ga4.js"
import {setThemeColors} from "../scripts/common.js";
import {useNavigate} from "react-router-dom";
export default function AboutComponent() {  
  const [scrollStep, setScrollStep] = useState(0);

  useEffect(() => { 
    setThemeColors("var(--palette-dark)", "var(--neutral)");
  },[]);

  return (
      <div id="about_container" data-testid="about_container">
        <div id="about_box_1">
          <div className="about_paragraph">
                <div className="divTitle">
                  <h2>ABOUT</h2>
                </div>
                <div id="about_box_1_body">
                  <p>I am a Polish-born, US-based full stack programmer with over 5 years of experience in the Fintech and E-Commerce industry.</p>
                  <p>I worked previously on <strong>.NET</strong>, <strong>Java</strong>, <strong>Xamarin</strong> & <strong>React</strong> projects among others - my current favorite stack is <strong>React.js</strong>!</p>
                  <br></br>
                  <p>I have contributed to every step of the development lifecycle in a consulting environment.  I prioritize a positive, personal relationship with clients by being transparent and responsive. I have a knack for translating the technical into the non-technical, earning trust with those around me. </p> 
                  <br></br>
                  <p>The most important values to me are <strong>authenticity</strong>, <strong>simplicity</strong>, and <strong>enthusiasm</strong>. When I apply these in my life and work, I get the best results!</p>
                </div>
          </div>
        </div>
        <div id="about_divider_2">
          <svg viewBox="0 0 1440 320">
            <path id="wave" className='divider_wave' d="M0,32L40,64C80,96,160,160,240,165.3C320,171,400,117,480,117.3C560,117,640,171,720,186.7C800,203,880,181,960,186.7C1040,192,1120,224,1200,240C1280,256,1360,256,1400,256L1440,256L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path>
            <text textAnchor="middle">
                <textPath id="get_to_know_me" href="#wave" startOffset="10%">
                  <animate attributeName="startOffset" from="25%" to="25%" begin="0s" dur="10s" repeatCount="indefinite"></animate>
                get to know me
              </textPath>
          </text>
          </svg>
        </div>
        <div id="about_box_3"> 
          <div id="about_box_3_container">
         <div className="about_header">
              <img alt="Self portrait by Aleks" src="/assets/img/about-me.png"></img>
            </div>
          <div id="about_cards">
            <div className="about_cards_row">
              <div className="about_cards_item rounded-corners">
                <div className="about_cards_item_label">From</div>
                <div className="about_cards_item_val">Chicago, Illinois</div>
              </div>
              <div className="about_cards_item rounded-corners">
                <div className="about_cards_item_label">Education</div>
                <div className="about_cards_item_val">B.S., M.S. in Computer Science</div>
              </div>
            </div>
            <div className="about_cards_row">
              <div className="about_cards_item rounded-corners">
                <div className="about_cards_item_label">Best Movie of 2024</div>
                <div className="about_cards_item_val">Dune 2</div>
              </div>
              <div className="about_cards_item rounded-corners">
                <div className="about_cards_item_label">Favorite Travels of 2024</div>
                <div className="about_cards_item_val">Tuscany, Italy</div>
              </div>
            </div>
            <div className="about_cards_row">
              <div className="about_cards_item rounded-corners">
                <div className="about_cards_item_label">Authorized to Work In</div>
                <div className="about_cards_item_val">USA, EU</div>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div id="about_divider_1">
          <svg viewBox="0 0 1440 320">
            <path className='divider_wave' d="M0,32L40,64C80,96,160,160,240,165.3C320,171,400,117,480,117.3C560,117,640,171,720,186.7C800,203,880,181,960,186.7C1040,192,1120,224,1200,240C1280,256,1360,256,1400,256L1440,256L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path>
          </svg>
        </div>
        <div id="about_box_2">
          <div id="about_box_2_title" className="rounded-corners">
            <h2>My framework</h2>
          </div>
          <svg className="view-desktop" viewBox="0 0 200 50" xmlns="http://www.w3.org/2000/svg">
            <line x1="100" y1="0" x2="100" y2="20" stroke="var(--palette-dark)" strokeWidth="0.5"></line>
            <line x1="0" y1="20" x2="200" y2="20" stroke="var(--palette-dark)" strokeWidth="0.5"></line>
            <line x1="0" y1="20" x2="0" y2="50" stroke="var(--palette-dark)" strokeWidth="1"></line>
            <line x1="100" y1="20" x2="100" y2="50" stroke="var(--palette-dark)" strokeWidth="0.5"></line>
            <line x1="200" y1="20" x2="200" y2="50" stroke="var(--palette-dark)" strokeWidth="1"></line>
          </svg>
          <svg className="view-mobile" viewBox="0 0 200 50" xmlns="http://www.w3.org/2000/svg">
            <line x1="100" y1="0" x2="100" y2="20" stroke="var(--palette-dark)" strokeWidth="0.5"></line>
            <line x1="100" y1="20" x2="100" y2="50" stroke="var(--palette-dark)" strokeWidth="0.5"></line>
          </svg>
          <div className="about_paragraph">
            <div>
              <h3>Team Leader</h3>
              <span>Engage the team to its highest potential. No team member gets left behind, and I guide them until they can understand the requirements on their own terms. </span>
            </div>
            <svg className="view-mobile" viewBox="0 0 200 50" xmlns="http://www.w3.org/2000/svg">
            <line x1="100" y1="0" x2="100" y2="20" stroke="var(--palette-dark)" strokeWidth="0.5"></line>
            <line x1="100" y1="20" x2="100" y2="50" stroke="var(--palette-dark)" strokeWidth="0.5"></line>
          </svg>
            <div>
              <h3>Visual Learner</h3>
              <span>Analyze how we interpret information on our screens every day.   The data must be conveyed intuitively and logically.</span>
            </div>
            <svg className="view-mobile" viewBox="0 0 200 50" xmlns="http://www.w3.org/2000/svg">
            <line x1="100" y1="0" x2="100" y2="20" stroke="var(--palette-dark)" strokeWidth="0.5"></line>
            <line x1="100" y1="20" x2="100" y2="50" stroke="var(--palette-dark)" strokeWidth="0.5"></line>
          </svg>
            <div>
              <h3>Problem Solver</h3>
              <span>Always thinking of how to improve the quality of my life and those around me.  The trick is to find the time to code them out!</span>
            </div>
          </div>
        </div>
      </div>
    )
}
