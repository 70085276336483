import React, {useEffect} from 'react'
import AboutComponent from "../components/AboutComponent"
import TopMenuComponent from '../components/TopMenuComponent.js'
import {sendGAPageView} from "../scripts/ga4.js"

export default function About() {
  useEffect(() => {
    sendGAPageView("/about", "About")
  }, []);
  return (
    <>
    <TopMenuComponent></TopMenuComponent>
    <div className="main_content">
      <AboutComponent></AboutComponent>
    </div>
    </>
  )
}