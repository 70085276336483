import ReactGA from 'react-ga4';

var sendGAEvent = (_category, _action, _label = "") => {
    try{
        ReactGA.event({
            category: _category,
            action: _action,
            label: _label,
        });
        return true;
    } catch (error) {
        console.error("GA Event Failed");
        return false;
    }
}
export var sendGAPageView = (relativePath, pageTitle) => {
    try{
        ReactGA.send({ 
            hitType: "pageview", 
            page: relativePath, 
            title: pageTitle
        });
        return true;
    } catch (error) {
        console.error("GA PageView Failed");
        return false;
    }
}
export var navClickedGAEvent = (navStr) => {
    if (navStr == "about") return sendGAEvent("Navigation Internal", "About Clicked");
    if (navStr == "experience") return sendGAEvent("Navigation Internal", "Experience Clicked");
    if (navStr == "contact") return sendGAEvent("Navigation Internal", "Contact Clicked");
    if (navStr == "journal") return sendGAEvent("Navigation Internal", "Journal Clicked");
    if (navStr == "name-side") return sendGAEvent("Navigation Internal", "Name Clicked", "Sidebar");
    if (navStr == "name-main") return sendGAEvent("Navigation Internal", "Name Clicked", "Home");
}
export var linkClickedGAEvent = (linkName) => {
    if (linkName == "github") return sendGAEvent("Navigation External", "GitHub Icon Clicked");
    if (linkName == "linkedin") return sendGAEvent("Navigation External", "LinkedIn Icon Clicked");
    if (linkName == "mail") return sendGAEvent("Navigation External", "Mail Icon Clicked");
    if (linkName == "neopets") return sendGAEvent("Navigation External", "Neopets Link Clicked");
}
export var readMoreClickedGAEvent = (stepNum) => {
    return sendGAEvent("About Page", "Read More Clicked", stepNum);
}
export var experiencePageGAEvent = (interaction, projectID = "", tags = "") => {
    if (interaction == "filter-toggle") return sendGAEvent("Experience Page", "Filter Toggled");
    if (interaction == "filter-selectall") return sendGAEvent("Experience Page", "Filter Select All Clicked");
    if (interaction == "filter-tag") return sendGAEvent("Experience Page", "Filter Tag Changed", tags);
    if (interaction == "project-toggle") return sendGAEvent("Experience Page", "Project Toggled", projectID);
}
export var contactMeGAEvent = (interaction) => {
    if (interaction == "send") return sendGAEvent("Contact Page", "Message Submit");
    if (interaction == "msg-fail") return sendGAEvent("Contact Page", "Message Fail");
    if (interaction == "msg-success") return sendGAEvent("Contact Page", "Message OK");
}