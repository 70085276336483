import React, { useState, useEffect, useContext } from "react";
import Form from 'react-bootstrap/Form';
import Collapse from 'react-bootstrap/Collapse';
import Accordion from 'react-bootstrap/Accordion';
import LoaderComponent from "./LoaderComponent";
import {experiencePageGAEvent} from "../scripts/ga4"
import { setThemeColors } from "../scripts/common";
import { get_data } from "../scripts/webservice";
const ExperienceComponent = (props) => {
    const [AccordionExpandedArr, setAccordionExpandedArr] = useState([]);
    const [AccordionDict, setAccordionDict] = useState([]);
    useEffect(() => {
        fetchData();
        setThemeColors("var(--palette-light)", "var(--accent-darker)");
    }, []);
    //determines if the advanced filter menu is expanded or collapsed
    const [filterOpen, setFilterOpen] = useState(false);
    //stores all selected tags from expanded filter
    const [tagsSelected, setTagsSelected] = useState([]);
    //stores the string in the minimized filter
    const [tagsSelectedAsString, setTagsSelectedAsString] = useState("None");
    //contains all experience details 
    const [experienceFromAPI, setExperienceFromAPI] = useState(null);
    //contains all tags for filtering
    const [tagsFromAPI, setTagsFromAPI] = useState([]);
    //all filtered experiences based on selected tags
    const [arrFilteredProjects, setArrFilteredProjects] = useState(null);
    
    const onTagClicked = (e) => {
        toggleFiltersByTag(e.target.closest(".tagContainer"));
    }
    const getExperienceItemExpanded = (project_id)=>{
        console.log(project_id);
        console.log("experience_item " + AccordionDict[project_id].toString());
        return "experience_item " + AccordionDict[project_id].toString();
        
    }
     //toggle css class for tag button & add to filter string
     const toggleFiltersByTag = (tagElement, isSelectAll = false) => {
        let tagAsString = tagElement.textContent;
        let filtersSoFar = tagsSelected;
        //add or remove this tag 
        if (filtersSoFar.indexOf(tagAsString) < 0){
            if (!isSelectAll){
                experiencePageGAEvent("filter-tag", "", tagAsString)
            }
            filtersSoFar.push(tagAsString);
        }
        else {
            filtersSoFar.splice(filtersSoFar.indexOf(tagAsString), 1);
        }
        setTagsSelected(filtersSoFar);
        if (filtersSoFar.length >0){
            setTagsSelectedAsString(filtersSoFar.join(","))
        }
        else {
            setTagsSelectedAsString("None");
        }
        //toggle the button class 
        tagElement.classList.toggle("selected");

        //refine the results based on selected tags
        filterProjectsByTags(filtersSoFar);
       
    }
    //evaluate the select all switch when dom changes
    const evaluateSelectAll = () => {
        return document.querySelectorAll(".tagContainer").length == document.querySelectorAll(".tagContainer.selected").length
    }
    //toggles all available tags on the filter
    const onSelectAllTagsClicked = (e) => {
        let buttonsToToggle = [];
        if (e.target.checked){
            //add all remaining tags to filter
            buttonsToToggle = document.querySelectorAll(".tagContainer:not(.selected)")
        }
        else{
             //deselect all tags
            buttonsToToggle = document.querySelectorAll(".tagContainer.selected")
        }
        Array.prototype.forEach.call(buttonsToToggle, (e) => toggleFiltersByTag(e, true));
        experiencePageGAEvent("filter-selectall");
    }
    //updates the projects to display based on selected tags
    const filterProjectsByTags = (arrTags) => {
        //if no tags are being used for filter, display all projects
        if (arrTags.length == 0){
            setArrFilteredProjects(experienceFromAPI);
        } 
        else {
            //filter by tags
            let arrProjects = [];
            Array.prototype.forEach.call(arrTags, (tag) => 
            {
               var filteredProjects = experienceFromAPI.filter(exp => Object.values(exp.tags).some(val => typeof val === "string" && val ==(tag)));
               Array.prototype.forEach.call(filteredProjects, (project) => 
               {
                  if (!arrProjects.includes(project)) arrProjects.push(project);
               });
           });
           setArrFilteredProjects(arrProjects);
        }

    }
    const fetchData = async () => {
        let data = await get_data("Experience")
        let TempAccordionDict = ["expanded"]
         if (data.length > 0 && data[0] != undefined){
            for (var i in data[0]){
                TempAccordionDict.push("expanded");
            }
             setExperienceFromAPI(data[0]);
             setArrFilteredProjects(data[0]);
            //set the open/closed state for the accordion
            let expandedArr = Array.from({length: data[0].length}, (v, i) => "") 
            setAccordionExpandedArr(expandedArr);
            if (data.length > 1 && data[1] != undefined) {
                setTagsFromAPI(data[1]);
            }
         }
         setAccordionDict(TempAccordionDict);
    }
    return (
    <>
    <div id="experienceContainer" data-testid="experienceContainer" className="flexCol">  
        <div id="experience_divider_1">
          <svg viewBox="0 0 1440 320">
            <path className='divider_wave' d="M0,32L40,64C80,96,160,160,240,165.3C320,171,400,117,480,117.3C560,117,640,171,720,186.7C800,203,880,181,960,186.7C1040,192,1120,224,1200,240C1280,256,1360,256,1400,256L1440,256L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path>
          </svg>
        </div>
        <div id="experienceBox" className="flexCol">
            <div className="divTitle">
                <h2><strong>EXPERIENCE</strong></h2>
                <h3>Here's some of the projects I contributed to.</h3>
            </div> 
            <>
            { !experienceFromAPI ? <></>
            :
            <div id="experienceResults">
               <div id="filterBox">
                    <div id="filterMinimized" onClick={() => {setFilterOpen(!filterOpen); experiencePageGAEvent("filter-toggle");}} aria-controls="filterMaximized" aria-expanded={filterOpen}>
                        <span><strong>Filters</strong></span>
                        <svg className={filterOpen ? "expanded" : ""} fill="#000000" viewBox="0 0 330 330">
                            <path d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393  c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393  s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"/>
                        </svg>
                    </div>
                    <Collapse in={filterOpen}>             
                        <div id="filterMaximized">
                            <hr></hr>
                            <div id="selectAll" className="flexRow">
                                <span><strong>SELECT ALL</strong></span>
                                <Form>
                                    <Form.Check // prettier-ignore
                                        custom
                                        type="switch"
                                        id="switch-select-all"
                                        onClick={(e) => onSelectAllTagsClicked(e)}
                                        checked={evaluateSelectAll()}
                                        label=""/>
                                    </Form>
                            </div>
                            <div id="selectTag" className="flexRow flexWrap">
                                {tagsFromAPI.map(tag => 
                                    <div className="tagContainer" onClick={(e) => onTagClicked(e)}>
                                        <span>{tag}</span>
                                    </div>
                                )} 
                            </div>
                        </div>
                    </Collapse>
                </div>   
                {arrFilteredProjects.length == 0 ? 
                    <div className="experience_item no_results">
                        <div className="flexCol">                      
                            <span className="exp_project_name">No results found.</span>
                            <hr></hr>
                            <span className="exp_project_description">Please check back soon for more!</span>
                        </div>
                    </div>  
               :(
                <div id="experience_accordion">
                {arrFilteredProjects.map(nav => (
                        <div className={"experience_item " + AccordionDict[nav.project_id]} item-idx={nav.project_id} key={nav.id}>
                                <div className="flexCol">  
                                <span className="exp_position">{nav.company_name}</span>                    
                                <h4 className="exp_project_name">{nav.project_name}</h4>
                                <span className="exp_position">{nav.project_role}</span>
                                <span className="exp_dates"><em>{nav.project_start_string} to {nav.project_end_string}</em></span>
                                <h5 className="exp_project_description">{nav.project_description}</h5>
                                </div>
                            <div style={{display: AccordionDict[nav.project_id] == "collapsed" ? "none" : "block"}} className="experience_item_body">
                                <div className="flexRow flexWrap exp_tags">
                                    {nav.tags.map(tag => (
                                        <span key={tag}>{tag}</span>
                                    ))} 
                                </div>
                                <ul className="exp_details">
                                    {nav.details.map(detail => (
                                        <li key={detail}>{detail}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                ))}  
                </div>
                )}    
                </div>
            } 
            </>  
        </div>
        <div id="experience_divider_2">
          <svg viewBox="0 0 1440 320">
            <path className='divider_wave' d="M0,32L40,64C80,96,160,160,240,165.3C320,171,400,117,480,117.3C560,117,640,171,720,186.7C800,203,880,181,960,186.7C1040,192,1120,224,1200,240C1280,256,1360,256,1400,256L1440,256L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path>
          </svg>
        </div>
     </div>
    </>
  );
};

export default ExperienceComponent;
