import React, { useState, useRef, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { submit_contact_form } from "../scripts/webservice.js";
import ReCAPTCHA from "react-google-recaptcha";
import {contactMeGAEvent} from "../scripts/ga4.js"
import { setThemeColors } from "../scripts/common.js";
import Modal from 'react-bootstrap/Modal';

const ContactFormComponent = (props) => {
  const recaptchaReference = useRef(null);
  const [formValidated, setFormValidated] = useState(false);
  const [contactResponse, setContactResponse] = useState("")
  const [captchaClassName, setCaptchaClassName] = useState("");
  const [showContactResponse, setShowContactResponse] = useState(false);
  const handleCloseContactResponse = () => setShowContactResponse(false);
  const useCaptcha = props.useCaptcha;
  useEffect(() => {
    setThemeColors("var(--palette-dark)", "var(--palette-light)");
  }, []);

  //on captcha change - reset the validation for captcha 
  const onCaptchaChanged = async () => {
    setCaptchaClassName("");
  };

  //on form submit - set validation and send
  const handleFormSubmit = async (event) => {
    if (document.querySelector("#formMessage").validity.valid && document.querySelector("#formName").validity.valid && document.querySelector("#formEmail").validity.valid){
      //form is valid
      if (recaptchaReference.current.getValue().length > 0){   
        //captcha is valid 
        await verifyCaptchaAndSubmit(document.getElementById('formName').value, document.getElementById('formEmail').value, document.getElementById('formMessage').value);
      }
      else{
        //captcha invalid
        setCaptchaClassName("invalid");
        event.preventDefault();
        event.stopPropagation();
      }
    }
    else {
      //form invalid
      event.preventDefault();
      event.stopPropagation();
    }
    setFormValidated(true);
  }
  //validate - verify captcha, send smtp, display api response, send GA event
  const verifyCaptchaAndSubmit = async (form_name, form_email, form_body) => {
    contactMeGAEvent("send");

    let res = await submit_contact_form(recaptchaReference.current.getValue(), form_name, form_email, form_body);
    //set response text
    setContactResponse(res);
    setShowContactResponse(true);
  //  { response: "ok", response_title: "Thank you!", response_body: "Your message was received.", smtp_success: 1},

    if (res.data.smtp_success == 1){
      contactMeGAEvent("msg-success");
      //reset form and captcha
      document.getElementById("formContact").reset()
      window.grecaptcha.reset();
    }
    else {
      contactMeGAEvent("msg-fail");
    }
  };
  //reset captcha, start new form, hide form response
  const newForm = async () => {
      window.grecaptcha.reset();
      document.getElementById("formContact").reset();
      setShowContactResponse(false);
    };
  return (
    <>
    <div id="contactFormContainer" data-testid="contactFormContainer" className="flexCol">
    <div id="contact_divider_1">
          <svg viewBox="0 0 1440 320">
            <path className='divider_wave' d="M0,32L40,64C80,96,160,160,240,165.3C320,171,400,117,480,117.3C560,117,640,171,720,186.7C800,203,880,181,960,186.7C1040,192,1120,224,1200,240C1280,256,1360,256,1400,256L1440,256L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path>
          </svg>
      </div>
      <div id="contactFormBox" className="flexCol">
        <div className="divTitle">
            <h2><strong>CONTACT</strong></h2>
            <h3>Thanks for checking my site out. Send me feedback or get in touch with me here.</h3>
        </div>
        <div id="divContact">
          <Form id="formContact" className="formContactContainer" validated={formValidated} onSubmit={handleFormSubmit}>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Control required type="email" placeholder="Your Email" pattern="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?" />
              <Form.Control.Feedback type="invalid"> Provide a valid email.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Control required type="textarea" placeholder="Your Name" pattern="[A-Za-z0-9 ]+" />
              <Form.Control.Feedback type="invalid"> Who's asking? Letters, numbers and spaces only.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formMessage">
              <Form.Control required as="textarea" placeholder="Write a few words." minLength={25} maxLength={2000} rows={5} />
              <Form.Control.Feedback type="invalid">25 - 2000 characters.</Form.Control.Feedback>
            </Form.Group>
            <span className={captchaClassName} id="captchaFeedback">Verify Captcha</span>
            <ReCAPTCHA 
              sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} 
              ref={recaptchaReference} 
              size="compact"
              onChange={onCaptchaChanged} className="reCaptcha" />
            <Button onClick={handleFormSubmit} variant="primary" size="lg" type="button">
              SEND
            </Button>
          </Form> 

          <Modal id="contactResponse" show={showContactResponse} onHide={handleCloseContactResponse}>
            <Modal.Header>
              <Modal.Title><h2>{contactResponse.response_title}</h2></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <span>{contactResponse.response_body}</span>
            </Modal.Body>
            <Modal.Footer>
              <button type="button" onClick={async () => {await newForm()}}>GO BACK TO FORM</button>         
            </Modal.Footer>
          </Modal>
        </div>
      </div>
      <div id="contact_divider_2">
          <svg viewBox="0 0 1440 320">
            <path className='divider_wave' d="M0,32L40,64C80,96,160,160,240,165.3C320,171,400,117,480,117.3C560,117,640,171,720,186.7C800,203,880,181,960,186.7C1040,192,1120,224,1200,240C1280,256,1360,256,1400,256L1440,256L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path>
          </svg>
      </div>
     </div>
    </>
  );
};

export default ContactFormComponent;
