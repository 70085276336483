import React, { useState, useEffect, useContext } from "react";
import {get_data, get_journal_data_by_entry} from "../scripts/webservice.js"
import {
  Link,
  useParams,
} from "react-router-dom";
import {sendGAPageView} from "../scripts/ga4.js"
import { setThemeColors } from "../scripts/common.js";

const JournalEntryComponent = () => {
  const { id } = useParams();
  const [JournalData, setJournalData] = useState(null);
  
  useEffect(() => {
    setThemeColors("var(--palette-light)", "var(--palette-dark)");
    fetchData();
    let entryID = id
    if (entryID.length == 0){
      entryID = ""
    }
    sendGAPageView("/journalentry/" + entryID, "Journal Entry")
  }, []);
  const fetchData = async () => {
    let data = await get_data("journalentry", id);
    setJournalData(data);
  }
  return (
    <>
    <div id="journal_container" data-testid="journal_entry_container" className="flexCol">
      {JournalData==null ? <></>: 
            <div id="entry_box" className="flexCol">
               <>
                <div className="divEntryTitle">
                    <h2><strong>{JournalData.entry_title}</strong></h2>
                    <h3>{JournalData.entry_date}</h3>
                </div> 
                <div id="journal_main">
                    <div className="journal_entry_body">
                      {JournalData.entry_body? JournalData.entry_body.map(item => 
                                    <p>{item}</p> )
                      :
                      <></> }
                    </div>
                </div>
               </> 
                <Link className="journal_back" to={`/journal`}>
                  <svg viewBox="0 0 75 50" xmlns="http://www.w3.org/2000/svg">        
                            <g>
                              <line x1="71" y1="0" x2="71" y2="25" stroke="var(--palette-dark)" strokeWidth="8" style={{transform: "rotateZ(-45deg)", transformOrigin: 'center right'}}></line>
                              <line x1="71" y1="25" x2="71" y2="50" stroke="var(--palette-dark)" strokeWidth="8" style={{transform: "rotateZ(45deg)", transformOrigin: 'center right'}}></line>
                            </g>
                            <g style={{transform: "translateX(-30%)"}}>
                              <line x1="71" y1="0" x2="71" y2="25" stroke="var(--palette-dark)" strokeWidth="8" style={{transform: "rotateZ(-45deg)", transformOrigin: 'center right'}}></line>
                              <line x1="71" y1="25" x2="71" y2="50" stroke="var(--palette-dark)" strokeWidth="8" style={{transform: "rotateZ(45deg)", transformOrigin: 'center right'}}></line>
                            </g>
                            <g style={{transform: "translateX(-60%)"}}>
                              <line x1="71" y1="0" x2="71" y2="25" stroke="var(--palette-dark)" strokeWidth="8" style={{transform: "rotateZ(-45deg)", transformOrigin: 'center right'}}></line>
                              <line x1="71" y1="25" x2="71" y2="50" stroke="var(--palette-dark)" strokeWidth="8" style={{transform: "rotateZ(45deg)", transformOrigin: 'center right'}}></line>
                            </g>
                    </svg>
                </Link>
            </div>      
      }
    </div>
    </>
  );
};

export default JournalEntryComponent;
