import React, {useEffect} from 'react'
import TopMenuComponent from '../components/TopMenuComponent.js'
import JournalComponent from '../components/JournalComponent'
import {sendGAPageView} from "../scripts/ga4.js"

export default function Journal() {
  useEffect(() => {
    sendGAPageView("/journal", "Journal")
  }, []);
  return (
    <>
    <TopMenuComponent></TopMenuComponent> 
    <div className="main_content">
      <JournalComponent></JournalComponent>
    </div>
    </>
  )
}